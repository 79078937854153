
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
  },
  computed: {
    ...mapGetters({
      getAccessRights: "session/getAccessRights",
    }),
    items(): { label: string; to: string }[] {
      return [
        {
          label: "Scheduling",
          to: "/production/scheduling",
        },
        {
          label: "Work Order",
          to: "/production/work-order",
        },
      ];
    },
  },
  provide() {
    return {
      getAccessRights: this.getAccessRights("/production") as AccessRights,
    };
  },
});
